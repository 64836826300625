@tailwind components;
@tailwind utilities;


.NavigationSection {
    @apply pt-44 pb-24 relative
}


.SignOverlay {
    position: absolute;
    width: 130px;
    height: 35px;
}

.SignOne {
    top:175px;
    left:270px;
    background-color: #C4DDF2;
}

.SignTwo {
    top:210px;
    left:270px;
    background-color: #F2E7AC;
}

.SignThree {
    top:245px;
    left:270px;
    background-color: #8A6DA6;
}

.SignFour {
    top:280px;
    left:270px;
    background-color: #D9A3BC;
}
