@tailwind base
@tailwind utilities
@tailwind components;

.LocationContainer {
    @apply relative overflow-hidden}

.ClickOverlay {
    @apply absolute cursor-pointer bg-transparent
    /*@apply absolute cursor-pointer opacity-50*/
}

.BackgroundImage {
    @apply bg-center bg-no-repeat
}
