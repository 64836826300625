.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
   @apply bg-gray-medium
}

.burger {
    @apply z-20;
    font-size: 8px;
}

.menu-link {
    @apply py-1 hover:cursor-pointer hover:text-yellow-dark
}

.menu-sub {
    @apply pl-2 text-sm
}
